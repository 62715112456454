import { InputFieldsType } from '@enaratech/funnel-helper';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';

export const inputFields: InputFieldsType[] = [
  {
    label: 'Card Number',
    field: 'cardnumber',
    size: 12,
    inputType: 'stripe',
    component: CardNumberElement,
    name: 'Card Number',
    track: false,
  },
  {
    label: 'Expiry',
    field: 'expiration',
    size: 6,
    inputType: 'stripe',
    component: CardExpiryElement,
    name: 'Expiry',
    track: false,
  },
  {
    label: 'CVC',
    field: 'cvc',
    size: 6,
    inputType: 'stripe',
    component: CardCvcElement,
    name: 'CVC',
    track: false,
  },
  {
    label: 'Address',
    field: 'street',
    size: 12,
    name: 'Card holder address',
    track: true,
  },
  {
    label: 'Zip Code',
    field: 'zipCode',
    size: 6,
    name: 'Card holder zip code',
    track: true,
  },
  {
    label: 'State',
    field: 'state',
    size: 6,
    name: 'Card holder state',
    track: true,
  },
  {
    label: 'City',
    field: 'city',
    size: 12,
    name: 'Card holder city',
    track: true,
  },
];

export const PLAN_OPTIONS = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annual',
    value: 'annual',
  },
];
