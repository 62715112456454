import {
  AvailableLanguages,
  Clinic,
  LeadSystemSource,
  ProgramType,
  scheduleClient,
  SchedulingWorkflow,
  Step,
  User,
} from '@enaratech/funnel-helper';
import { Dispatch } from 'react';
import Toast from 'src/components/Common/Toast/Toast';
import { SET_SSB_INFO, SSBActionTypes, SSBFlowState } from 'src/contexts/ssb/types';
import { capitalizeToKebabCase } from 'src/utils/array';

export const initializeSchedulingAction = async ({
  context,
  setters,
  setState,
}: {
  context: {
    clinic: Clinic;
    user: User;
    stepsRef: React.MutableRefObject<Step[] | null>;
    selectedLanguage: AvailableLanguages | null;
  };
  setters: {
    setSelectedLanguage: (value: React.SetStateAction<AvailableLanguages | null>) => void;
  };
  setState: Dispatch<SSBActionTypes>;
}) => {
  const currentStep = context.stepsRef.current?.find((s) => s.active) || null;

  const fetchedSteps = await scheduleClient.getStepsForMember({
    clinicId: context.clinic!.details.clinicId,
    memberId: context.user!.id,
    programType: context.user!.programType as ProgramType.InClinic | ProgramType.Remote,
    systemSource: capitalizeToKebabCase(context.user!.systemSource) as LeadSystemSource,
    schedulingWorkflow: SchedulingWorkflow.SelfServeBooking,
  });

  if (fetchedSteps?.every((step) => step.scheduled)) {
    setState({ type: SET_SSB_INFO, payload: SSBFlowState.AllScheduled });
    return;
  }

  if (currentStep && !context.selectedLanguage) {
    const {
      config: { allowedLanguages },
    } = currentStep;

    if (allowedLanguages.length === 1) {
      setters.setSelectedLanguage(allowedLanguages[0]);
    } else {
      setState({ type: SET_SSB_INFO, payload: SSBFlowState.WaitingForUserSelection });
      return;
    }
  }

  if (!fetchedSteps) {
    return Toast.notification(
      'error',
      'There was an error obtaining the information. Please reload the page or wait for a support member to contact you, we are looking for the solution.'
    );
  }

  context.stepsRef.current = fetchedSteps;

  setState({ type: SET_SSB_INFO, payload: SSBFlowState.FetchAppointmentsAvailability });
};
